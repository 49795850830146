import axios from "axios";

const createAxiosInstance = (baseURL: string | undefined) => {
  const api = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          if (refreshToken) {
            const newToken = await refreshAccessToken(refreshToken);
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return api(originalRequest);
          }
        } catch (err) {
          console.error("Refresh token failed", err);
          localStorage.clear();
          window.location.href = "/login"; // Redirect to login page
        }
      }
      return Promise.reject(error);
    }
  );

  const refreshAccessToken = async (refreshToken: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/token/refresh`,
        {
          refreshToken: refreshToken,
        }
      );
      const { token, refreshToken: newRefreshToken } = response.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", newRefreshToken);
      return token;
    } catch (error) {
      localStorage.clear();
      window.location.href = "/login"; // Redirect to login page
      throw error;
    }
  };
  return api;
};

const CdrAxios = createAxiosInstance(process.env.REACT_APP_CDR_URL);
const recordingAxios = createAxiosInstance(process.env.REACT_APP_RECORDING_URL);
export { CdrAxios, recordingAxios };
