import React, { useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import AuthLayout from "../layouts/AuthLayout";
import Logo from "../assets/img/loginAsset.png"; // Update the import path if necessary

interface LoginValues {
  email: string;
  password: string;
}

interface OtpValues {
  otp: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [verification, setVerification] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const onFinish = async (values: LoginValues) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/qauth/users/login`,
        values
      );
      if (res.data.message !== "OTP sent successfully. Please verify OTP.") {
        localStorage.setItem("id", res.data.data.id);
        localStorage.setItem("name", res.data.data.name);
        localStorage.setItem("email", res.data.data.email);
        localStorage.setItem("phone", res.data.data.phone);
        localStorage.setItem("role", res.data.data.role);
        localStorage.setItem("token", res.data.data.accessToken);
        localStorage.setItem("refreshToken", res.data.data.refreshToken);
        navigate("/"); // Redirect to the homepage
      } else {
        localStorage.setItem("requestId", res.data.data.requestId);
        localStorage.setItem("userId", res.data.data.userId);
        setVerification(true);
      }
    } catch (e: any) {
      setLoadings([]);
      messageApi.error(e.response?.data?.message || "An error occurred");
    }
  };

  const onFinishForVerification = async (values: OtpValues) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/qauth/users/otp/verify`,
        {
          userId: localStorage.getItem("userId"),
          requestId: localStorage.getItem("requestId"),
          code: values.otp,
        }
      );
      localStorage.removeItem("requestId");
      localStorage.removeItem("userId");
      localStorage.setItem("token", res.data.data.accessToken);
      localStorage.setItem("refreshToken", res.data.data.refreshToken);
      localStorage.setItem("id", res.data.data.id);
      navigate("/"); // Redirect to the homepage
    } catch (e: any) {
      setLoadings([]);
      messageApi.error("Invalid Code");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      {contextHolder}
      <div className="bg-transparent w-[80vw] lg:w-[60vw] md:w-[70vw] sm:w-[90vw] h-[80vh] mt-20 mx-auto rounded-lg flex flex-col sm:flex-row bg-white rounded-s-full ">
        <div className="relative hidden sm:block">
          <img
            className="h-full z-0 mx-auto object-fill rounded-md"
            src={Logo}
            alt="Login"
          />
        </div>
        <div className="bg-white flex flex-col w-full sm:w-2/3 p-10 justify-center">
          <Form
            name="basic"
            disabled={verification}
            className="pl-2 ml-0 sm:ml-20 mr-0 sm:mr-32 w-full sm:w-4/5 flex flex-col justify-between"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex justify-center">
              <p className="text-lg font-semibold mb-4">{"Sign in"}</p>
            </div>
            <Form.Item
              label={"Email"}
              name="email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Password"}
              name="password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            {!verification && (
              <Form.Item>
                <div className="flex justify-center content-center">
                  <Button
                    loading={loadings[1]}
                    onClick={() => enterLoading(1)}
                    className="hover:bg-purple-300 duration-300 flex justify-center items-center"
                    style={{
                      background: "#8201DC",
                      width: "50%",
                      marginRight: "0px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    <span>{"Sign In"}</span>{" "}
                    <ArrowRightOutlined className="mb-2" />
                  </Button>
                </div>
              </Form.Item>
            )}
          </Form>
          {verification && (
            <Form
              name="verification"
              className="pl-2 ml-0 sm:ml-20 mr-0 sm:mr-32 w-full sm:w-4/5 flex flex-col justify-between"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinishForVerification}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={"Enter the verification code"}
                name="otp"
                className="mb-4"
                rules={[
                  {
                    required: true,
                    message: "Please input the verification code",
                  },
                ]}
              >
                <Input placeholder={"Verify 6-digits"} />
              </Form.Item>
              <Form.Item>
                <div className="flex justify-center content-center">
                  <Button
                    loading={loadings[1]}
                    onClick={() => enterLoading(1)}
                    className="hover:bg-purple-300 duration-300"
                    style={{
                      background: "#8201DC",
                      width: "50%",
                      marginRight: "0px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    {"Verify"}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
