// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import "./index.css"
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
     theme={{
          token: {
            colorPrimary: "#8201DC",
          },
          components: {
            Tabs: {
              itemColor: "#8B909A",
              colorBorder: "#EAECF0",
              fontSize: 16,
              fontWeightStrong: 700,
            },
            Table: {
              headerBg: "#FCFCFD",
              headerColor: "#667085",
            },
            Button: {
              colorIconHover: "#550190",
              colorPrimaryHover: "#550190",
              colorInfoHover: "#550190",
              controlHeight: 44,
            },

            Input: {
              colorBgContainer: "#EFF1F999",
              controlHeight: 48,
              colorInfo: "#ABAFB1",
              borderRadius: 8,
              inputFontSize: 16,
              colorTextLabel: "#53545C",      
            },
            DatePicker: {
              colorBgContainer: "#EFF1F999",
              controlHeight: 48,
              borderRadius: 8,
              colorInfo: "#ABAFB1",
              inputFontSize: 16,
              cellHeight: 28,
              colorTextLabel: "#53545C",
            },
            Select: {
              colorBgContainer: "#EFF1F999",
              controlHeight: 48,
              colorInfo: "#ABAFB1",
              borderRadius: 8,
              colorTextLabel: "#53545C",
            },
            
            Menu: {
              activeBarHeight: 0,
            },
            Form: {
              labelColor:"#53545C"
            },
          },
        }}
    >
      <Router>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Router>
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
