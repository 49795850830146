import React from "react";
import { Layout } from "antd";
import Logout from "../components/auth/LogoutButton";

const { Header, Content } = Layout;

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <Header className="flex items-center justify-between p-4 max-h-24 bg-white shadow-md">
        <div className="logo">
          <img src="/logo.png" alt="Logo" width={50} height={60} />
        </div>
        <Logout />
      </Header>
      <Content className="p-8">{children}</Content>
    </Layout>
  );
};

export default MainLayout;
