import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Select,
  Button,
  Table,
  message,
  Tag,
  Dropdown,
  Menu,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  ExportOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import MainLayout from "../layouts/MainLayout";
import dayjs from "dayjs";
import { CdrData } from "../@types/crd";
import { debounce } from "lodash";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CdrAxios } from "../services/axiosInstance";

const { Option } = Select;
const { RangePicker } = DatePicker;

const MainPage: React.FC = () => {
  const [data, setData] = useState<CdrData[]>([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    from: "",
    to: "",
    status: null,
    direction: null,
    startDate: null,
    endDate: null,
  });
  const [sort, setSort] = useState<{ field?: string; order?: string }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const fetchData = async (
    page: number,
    pageSize: number,
    filters: any,
    sort: any
  ) => {
    setLoading(true);
    try {
      const params: any = {
        limit: pageSize,
        page: page,
        from: filters.from || undefined,
        to: filters.to || undefined,
        status: filters.status || undefined,
        direction: filters.direction || undefined,
        startDate: filters.startDate
          ? filters.startDate.toISOString()
          : undefined,
        endDate: filters.endDate ? filters.endDate.toISOString() : undefined,
      };
      if (sort.field && sort.order) {
        params.sortBy = sort.field;
        params.sortOrder = sort.order === "ascend" ? "asc" : "desc";
      }
      const response = await CdrAxios.get("/", {
        params: params,
      });
      setData(response.data.data.data);
      setPagination({
        current: page,
        pageSize,
        total: response.data.data.totalRecords,
      });
    } catch (error) {
      message.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, filters, sort);
  }, [pagination.current, pagination.pageSize, filters, sort]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSort({ field: sorter.field, order: sorter.order });
    setPagination({
      ...pagination,
      current: pagination.current,
    });
  };

  const handleExport = async (format: string) => {
    setExportLoading(true);
    try {
      let exportData = data;

      if (selectedRowKeys.length > 0) {
        exportData = data.filter((item: CdrData) =>
          selectedRowKeys.includes(item.cdrId)
        );
      } else {
        const params: any = {
          limit: pagination.total, // fetch all records
          page: 1,
          from: filters.from || undefined,
          to: filters.to || undefined,
          status: filters.status || undefined,
          direction: filters.direction || undefined,
          startDate: filters.startDate
            ? (filters.startDate as Date).toISOString()
            : undefined,
          endDate: filters.endDate
            ? (filters.endDate as Date).toISOString()
            : undefined,
        };
        if (sort.field && sort.order) {
          params.sortBy = sort.field;
          params.sortOrder = sort.order === "ascend" ? "asc" : "desc";
        }
        const response = await CdrAxios.get("/", { params: params });
        exportData = response.data.data.data;
      }

      if (format === "csv") {
        exportToCSV(exportData);
      } else if (format === "pdf") {
        exportToPDF(exportData);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to export data");
    } finally {
      setExportLoading(false);
    }
  };

  const exportToCSV = (data: CdrData[]) => {
    const csvRows = [];
    const headers = [
      "Date",
      "Caller ID",
      "From",
      "To",
      "Duration",
      "Talk Time",
      "Status",
      "Direction",
      "File Name",
    ];
    csvRows.push(headers.join(","));
    data.forEach((row) => {
      const values = [
        row.date ? dayjs(row.date).format("DD/MM/YYYY hh:mm:ss A") : "---",
        row.callerId,
        row.from,
        row.to,
        row.duration.toFixed(2),
        row.talkTime.toFixed(2),
        row.status,
        row.direction,
        row.recordingId ? `${row.recordingId}.wav` : "---",
      ];
      csvRows.push(values.join(","));
    });
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    saveAs(blob, "call_detail_records.csv");
  };

  const exportToPDF = (data: CdrData[]) => {
    const doc = new jsPDF({ orientation: "landscape", format: "a3" });
    const logoPath = "/logo.png"; // Path to the logo file
    const logoWidth = 20; // Width of the logo
    const logoHeight = 20; // Height of the logo
    const rowsPerPage = 30;
    const tableColumn = [
      "Date",
      "Caller ID",
      "From",
      "To",
      "Duration",
      "Talk Time",
      "Status",
      "Direction",
      "File Name",
    ];
    const tableRows: any[] = [];

    data.forEach((row) => {
      const rowData = [
        row.date ? dayjs(row.date).format("DD/MM/YYYY hh:mm:ss A") : "---",
        row.callerId,
        row.from,
        row.to,
        row.duration.toFixed(2),
        row.talkTime.toFixed(2),
        row.status,
        row.direction,
        row.recordingId ? `${row.recordingId}.wav` : "---",
      ];
      tableRows.push(rowData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      didDrawPage: (data) => {
        // Add the logo to the PDF
        doc.addImage(
          logoPath,
          "PNG",
          data.settings.margin.left,
          10,
          logoWidth,
          logoHeight
        );

        // Add page title
        doc.setFontSize(20);
        doc.text(
          "Call Record Details",
          doc.internal.pageSize.getWidth() / 2,
          30,
          { align: "center" }
        );

        // Add page number
        const pageNumber = doc.internal.pages;
        const totalPages = Math.ceil(tableRows.length / rowsPerPage);
        const pageStr = `Page ${pageNumber} of ${totalPages}`;
        doc.setFontSize(10);
        doc.text(
          pageStr,
          data.settings.margin.left,
          doc.internal.pageSize.getHeight() - 10
        );
      },
      margin: { top: 40 },
      theme: "grid",
    });

    doc.save("call_detail_records.pdf");
  };

  const menu = (
    <Menu onClick={(e) => handleExport(e.key)}>
      <Menu.Item key="csv">Export as CSV</Menu.Item>
      <Menu.Item key="pdf">Export as PDF</Menu.Item>
    </Menu>
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  // Debounced handlers for the from and to filters
  const debouncedSetFrom = useCallback(
    debounce((value: string) => {
      setFilters((prevFilters) => ({ ...prevFilters, from: value }));
    }, 300),
    []
  );

  const debouncedSetTo = useCallback(
    debounce((value: string) => {
      setFilters((prevFilters) => ({ ...prevFilters, to: value }));
    }, 300),
    []
  );

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    if (dates) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        startDate: dates[0],
        endDate: dates[1],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const columns = [
    {
      title: "Date/Time",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (text: string) =>
        text ? dayjs(text).format("DD/MM/YYYY hh:mm:ss A") : "---",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: true,
      render: (text: number) => text.toFixed(2),
    },
    {
      title: "Talk Time",
      dataIndex: "talkTime",
      key: "talkTime",
      sorter: true,
      render: (text: number) => text.toFixed(2),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let color = status === "answered" ? "green" : "volcano";
        let text = status === "answered" ? "Answered" : "Not Answered";
        return (
          <Tag color={color} className="!p-1" bordered>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Call Type",
      dataIndex: "direction",
      key: "direction",
      render: (direction: string) => {
        return (
          <Tag className="!p-1">
            {direction.charAt(0).toUpperCase() + direction.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: "File Name",
      dataIndex: "recordingId",
      key: "recordingId",
      render: (recordingId: string) =>
        recordingId ? `${recordingId}.wav` : "---",
    },
  ];

  return (
    <MainLayout>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="m-0 text-xl font-bold">Call Detail Records</h1>
        <Dropdown overlay={menu} disabled={exportLoading}>
          <Button
            icon={exportLoading ? <LoadingOutlined /> : <ExportOutlined />}
            type="primary"
            className="ml-auto"
            disabled={exportLoading}
          >
            Export <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className="mb-12">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold ">Filters</h2>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <label className="font-semibold inline-block mb-1">
              From Dialer
            </label>
            <Input
              placeholder="Search by From dialer"
              allowClear
              onChange={(e) => debouncedSetFrom(e.target.value)}
            />
          </Col>
          <Col xs={24} md={8}>
            <label className="font-semibold inline-block mb-1">To Dialer</label>
            <Input
              placeholder="Search by To dialer"
              allowClear
              onChange={(e) => debouncedSetTo(e.target.value)}
            />
          </Col>
          <Col xs={24} md={8}>
            <label className="font-semibold inline-block mb-1">Status</label>
            <Select
              placeholder="Select Status"
              value={filters.status}
              allowClear
              onChange={(value) =>
                setFilters((prevFilters) => ({ ...prevFilters, status: value }))
              }
              style={{ width: "100%" }}
            >
              <Option value="answered">Answered</Option>
              <Option value="not_answered">Not Answered</Option>
            </Select>
          </Col>
          <Col xs={24} md={8}>
            <label className="font-semibold inline-block mb-1">Direction</label>
            <Select
              placeholder="Select Direction"
              value={filters.direction}
              allowClear
              onChange={(value) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  direction: value,
                }))
              }
              style={{ width: "100%" }}
            >
              <Option value="inbound">Inbound</Option>
              <Option value="outbound">Outbound</Option>
            </Select>
          </Col>
          <Col xs={24} md={8}>
            <label className="font-semibold inline-block mb-1">
              Date Range
            </label>
            <RangePicker
              showTime
              onChange={handleDateChange}
              allowClear
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        rowKey="cdrId"
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          responsive: true,
          total: pagination.total,
          pageSize: pagination.pageSize,
          current: pagination.current,
          className: "items-center",
        }}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        className="w-full"
      />
    </MainLayout>
  );
};

export default MainPage;
