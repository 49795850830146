// src/App.tsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import CdrPage from "./pages/CdrPage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./layouts/ProtectedRoute";

const App: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <CdrPage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default App;
